import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Box } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import bodyParser from '../../../pages/search/bodyParserNationalResources';
import ContactDetails from './contact-details';
import RedButton from '../../../components/RedButton/RedButton';

const useStyles = makeStyles((theme) => ({
  companyOverviewContainer: {
    padding: '20px 0',
  },
  companyLogo: {
    maxWidth: '500px',
    marginBottom: '15px',
    '& img': {
      maxWidth: '100%',
      maxHeight: '180px',
    },
  },
  details: {
    paddingBottom: '20px',
  },
  imageWidth: {
    width: '100%',
  },
  mobileContactDetails: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  familySuitable: {
    display: 'flex',
    flexDirection: 'row',
  },
  familySuitableDetails: {
    paddingBottom: '20px',
    marginLeft: '8px',
  },
}));

const CompanyOverview = ({ postDetails, setShowMobileMap }) => {
  const classes = useStyles();
  const { organisation, title } = postDetails;
  const familySuitable = postDetails.suitable_for_family_and_friends;
  const whatDetail = bodyParser(postDetails.what);
  const whereDetail = bodyParser(postDetails.where);
  const whoDetail = bodyParser(postDetails.who);
  const lastReviewDate = postDetails.date;
  const companyLogo = postDetails.company_logo || undefined;

  return (
    <Box className={classes.companyOverviewContainer} id="companyOverview">
      {companyLogo && companyLogo.source_url && (
        <Box className={classes.companyLogo}>
          <img
            data-testid="company-image"
            src={companyLogo.source_url}
            alt={companyLogo.alt_text}
          />
        </Box>
      )}
      {title && (
        <Typography variant="h1" className={classes.details}>
          <b>{title}</b>
        </Typography>
      )}
      {organisation && (
        <Typography variant="h3" className={classes.details}>
          <b>{organisation}</b>
        </Typography>
      )}
      <div className={classes.mobileContactDetails}>
        <Box
          className={`${classes.details}`}
          display={{ xs: 'inline-block', md: 'none' }}
        >
          <RedButton id="showMobileMapButton" onClick={setShowMobileMap}>
            Show on Map
          </RedButton>
        </Box>
        <ContactDetails contactDetails={postDetails} />
      </div>
      {whatDetail && (
        <>
          <Typography variant="h4">
            <b>What</b>
          </Typography>
          <Typography
            variant="body1"
            display="block"
            className={classes.details}
          >
            {whatDetail}
          </Typography>
        </>
      )}

      {whereDetail && (
        <>
          <Typography variant="h4">
            <b>Where</b>
          </Typography>
          <Typography
            variant="body1"
            display="block"
            className={classes.details}
          >
            {whereDetail}
          </Typography>
        </>
      )}

      {whoDetail && (
        <>
          <Typography variant="h4">
            <b>Who</b>
          </Typography>
          <Typography
            variant="body1"
            display="block"
            className={classes.details}
          >
            {whoDetail}
          </Typography>
        </>
      )}

      {familySuitable ? (
        <div className={classes.familySuitable}>
          <GroupIcon />
          <Typography
            variant="body1"
            color="textSecondary"
            display="block"
            className={classes.familySuitableDetails}
          >
            Available for friends and family
          </Typography>
        </div>
      ) : null}
      <Typography variant="body1" className={classes.details}>
        {`Last updated ${lastReviewDate}`}
      </Typography>
    </Box>
  );
};

export default CompanyOverview;

CompanyOverview.propTypes = {
  postDetails: PropTypes.shape({
    organisation: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    what: PropTypes.string.isRequired,
    where: PropTypes.string.isRequired,
    who: PropTypes.string.isRequired,
    suitable_for_family_and_friends: PropTypes.bool,
    date: PropTypes.string.isRequired,
    company_logo: PropTypes.shape({
      source_url: PropTypes.string.isRequired,
      alt_text: PropTypes.string,
    }),
  }).isRequired,
  setShowMobileMap: PropTypes.func,
};

CompanyOverview.defaultProps = {
  setShowMobileMap: null,
};

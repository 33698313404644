import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '80px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #545f6a',
    '& p': {
      textDecoration: 'underline',
      lineHeight: '140%',
      color: theme.palette.coreRed,
    },
    '& img': {
      marginRight: '20px',
      marginBottom: '0px',
    },
    '& button': {
      display: 'flex',
      alignItems: 'center',
      padding: '0',
      border: 'none',
      background: 'none',
      paddingLeft: '20px',
    },
  },
}));

const BackButtonSubHeader = ({ label, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <button id="backButton" type="button" onClick={onClick}>
        <ChevronLeftIcon />
        <Typography variant="body1">{label}</Typography>
      </button>
    </div>
  );
};

BackButtonSubHeader.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BackButtonSubHeader;

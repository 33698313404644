import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles, Typography, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { graphql } from 'gatsby';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as he from 'he';
import moment from 'moment';
import theme from '../../themeprovider/ccmTheme/index';
import Layout from '../../components/layout';
import ContactDetails from './components/contact-details';
import CompanyOverview from './components/company-overview';
import Map from '../../components/map/map';
import BackButton from '../../components/BackButton/BackButton';
import SEO from '../../components/seo';

export const query = graphql`
  query($slug: String!) {
    allWordpressPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          acf {
            organisation
            website
            phone_number
            contact_email
            contact_name
            city
            street
            postcode
            address_1
            address_2
            region
            latitude
            longitude
            what
            where
            who
            suitable_for_family_and_friends
            facebook
            instagram
            twitter
            youtube
            linkedin
            vimeo
            tiktok
            generic_social_media_link
            company_logo {
              source_url
              alt_text
            }
          }
          title
          date
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  mainContainer: {
    [theme.breakpoints.up('xs')]: {
      margin: '0px 20px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0px auto 0px',
    },
    justifyContent: 'center',
    maxWidth: '1280px',
  },
  leftContainer: {
    padding: '20px 20px 20px 0px',
  },
  rightContainerInner: {
    border: '0px',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      borderLeft: '1px solid #000',
      padding: '0px 20px',
    },
  },
  mapContainer: {
    height: '400px',
    display: 'none',
    padding: '0.8rem',
    boxShadow: '0px 4px 6px rgb(100 100 100 / 33%)',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  showMap: {
    display: 'block',
    padding: '0px',
    boxShadow: 'none',
  },
  noPadding: {
    padding: '0px',
  },
  backButton: {
    backgroundColor: '#1F3055',
    textTransform: 'none',
    height: '35px',
    margin: '20px 0px 10px 0px',
  },
  contactDetails: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginBottom: '3rem',
    },
  },
}));

const CareProviderPage = ({ data }) => {
  const classes = useStyles();
  const post = data.allWordpressPost.edges[0].node.acf;
  const { title, date } = data.allWordpressPost.edges[0].node;
  const [showMobileMap, setShowMobileMap] = useState(false);

  const latitude = parseFloat(
    post.latitude === '0' || '' ? '54.5' : post.latitude,
  );
  const longitude = parseFloat(
    post.longitude === '0' || '' ? '-4' : post.longitude,
  );
  const currentRoute = '/providerPage';

  const { region } = post;

  const back = () =>
    typeof window !== 'undefined' ? window.history.back() : '';

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title={he.decode(title)} />
        <div className={classes.mainContainer}>
          <div className={classes.backContainer}>
            <Button
              data-testid="backToSearch"
              variant="contained"
              onClick={() => back()}
              onKeyDown={() => back()}
              className={classes.backButton}
            >
              <ArrowBackIcon />
              <Typography variant="body1">Back to Results</Typography>
            </Button>
          </div>
          <Grid container>
            <Grid
              item
              md={6}
              xs={12}
              className={`${classes.leftContainer} ${
                showMobileMap ? classes.noPadding : ''
              }`}
            >
              {showMobileMap ? (
                <BackButton
                  label="Back to support centre details"
                  onClick={() => setShowMobileMap(!showMobileMap)}
                />
              ) : (
                <CompanyOverview
                  postDetails={{
                    ...post,
                    date: moment(date, 'YYYY-MM-DDTHH:mm:ss. sssZ').format(
                      'DD/MM/YYYY',
                    ),
                    title: he.decode(title),
                  }}
                  setShowMobileMap={setShowMobileMap}
                />
              )}
            </Grid>
            <Grid item md={6} xs={12} className={classes.rightContainer}>
              <Grid item xs={12} className={classes.rightContainerInner}>
                <Grid
                  item
                  xs={12}
                  className={`${classes.mapContainer} ${
                    showMobileMap ? classes.showMap : ''
                  }`}
                  data-testid="map-grid-item"
                >
                  <Map
                    currentRoute={currentRoute}
                    center={{
                      lat: latitude,
                      lng: longitude,
                    }}
                    postMarkers={
                      region === 'National'
                        ? []
                        : [
                            {
                              lat: latitude,
                              lng: longitude,
                              title,
                            },
                          ]
                    }
                    zoomLevel={region === 'National' ? 5 : 14}
                  />
                </Grid>
                <Grid className={classes.contactDetails}>
                  <ContactDetails contactDetails={post} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Layout>
    </ThemeProvider>
  );
};

CareProviderPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            acf: PropTypes.shape({
              organisation: PropTypes.string.isRequired,
              website: PropTypes.string.isRequired,
              phone_number: PropTypes.string,
              contact_email: PropTypes.string,
              contact_name: PropTypes.string,
              address_1: PropTypes.string,
              address_2: PropTypes.string,
              city: PropTypes.string,
              street: PropTypes.string,
              region: PropTypes.string.isRequired,
              postcode: PropTypes.string,
              latitude: PropTypes.string.isRequired,
              longitude: PropTypes.string.isRequired,
              what: PropTypes.string,
              where: PropTypes.string,
              who: PropTypes.string,
              suitable_for_family_and_friends: PropTypes.bool,
              company_logo: PropTypes.shape({
                source_url: PropTypes.string.isRequired,
                alt_text: PropTypes.string,
              }),
            }),
            title: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
          }),
        }),
      ),
    }),
  }).isRequired,
};

export default CareProviderPage;

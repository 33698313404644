import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Link } from '@material-ui/core';
import facebook from '../../../../static/icons/facebook.png';
import instagram from '../../../../static/icons/instagram.png';
import twitter from '../../../../static/icons/twitter.png';
import linkedin from '../../../../static/icons/linkedin.png';
import youtube from '../../../../static/icons/youtube.png';
import vimeo from '../../../../static/icons/vimeo.png';
import tiktok from '../../../../static/icons/tiktok.png';
import generic from '../../../../static/icons/generic.png';

const useStyles = makeStyles((theme) => ({
  contactDetailsContainer: {
    margin: '0px',
    [theme.breakpoints.up('md')]: {
      margin: '20px',
      paddingTop: '20px',
    },
  },
  backArrowContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  backArrow: {
    marginRight: '20px',
    marginBottom: '0px',
  },
  redUnderline: {
    textDecoration: 'underline',
    lineHeight: '140%',
    color: theme.palette.coreRed,
  },
  details: {
    paddingBottom: '30px',
  },
  addressDetails: {
    marginBottom: '1.5rem',
  },
  socialMediaContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: '30px',
    '& a': {
      marginRight: '20px',
      width: '50px',
    },
  },
}));

const ContactDetails = ({ contactDetails }) => {
  const classes = useStyles();
  const shortenURL = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
  return (
    <div className={classes.contactDetailsContainer}>
      <Typography variant="h4">
        <b>{contactDetails.website ? 'Website' : null}</b>
      </Typography>
      <div className={classes.details}>
        <Link
          href={contactDetails.website}
          className={classes.redUnderline}
          variant="body1"
          target="_blank"
          rel="noopener"
        >
          {contactDetails.website.match(shortenURL)}
        </Link>
      </div>

      {contactDetails.contact_name === '' ? (
        <></>
      ) : (
        <>
          <Typography variant="h4">
            <b>Contact Name</b>
          </Typography>
          <Typography
            variant="body1"
            display="block"
            className={classes.details}
          >
            {contactDetails.contact_name}
          </Typography>
        </>
      )}

      {contactDetails.phone_number === '' ? (
        <></>
      ) : (
        <>
          <Typography variant="h4">
            <b>Tel</b>
          </Typography>
          <Typography variant="body1" className={classes.details}>
            <Link
              href={`tel:${contactDetails.phone_number}`}
              className={classes.redUnderline}
              variant="body1"
              target="_blank"
              rel="noopener"
            >
              {contactDetails.phone_number}
            </Link>
          </Typography>
        </>
      )}

      {contactDetails.contact_email === '' ? (
        <></>
      ) : (
        <>
          <Typography variant="h4">
            <b>Email</b>
          </Typography>
          <Typography variant="body1" className={classes.details}>
            <Link
              href={`mailto:${contactDetails.contact_email}`}
              className={classes.redUnderline}
              variant="body1"
            >
              {contactDetails.contact_email}
            </Link>
          </Typography>
        </>
      )}
      {contactDetails.region === 'National' ? (
        <></>
      ) : (
        <div className={classes.addressDetails}>
          <Typography variant="h4">
            <b>Address</b>
          </Typography>
          {contactDetails.address_1 ? (
            <Typography variant="body1" display="block">
              {contactDetails.address_1}
            </Typography>
          ) : null}
          {contactDetails.address_2 ? (
            <Typography variant="body1" display="block">
              {contactDetails.address_2}
            </Typography>
          ) : null}
          {contactDetails.street ? (
            <Typography variant="body1" display="block">
              {contactDetails.street}
            </Typography>
          ) : null}
          {contactDetails.city ? (
            <Typography variant="body1" display="block">
              {contactDetails.city}
            </Typography>
          ) : null}
          {contactDetails.postcode ? (
            <Typography variant="body1" display="block">
              {contactDetails.postcode}
            </Typography>
          ) : null}
        </div>
      )}
      <Typography variant="h4" data-testid="social-media-text">
        <b>
          {contactDetails.twitter ||
          contactDetails.facebook ||
          contactDetails.youtube ||
          contactDetails.linkedin ||
          contactDetails.instagram
            ? 'Social media'
            : null}
        </b>
      </Typography>
      <div className={classes.socialMediaContainer}>
        {contactDetails.facebook && (
          <a
            href={contactDetails.facebook}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="facebook-logo"
          >
            <img src={facebook} alt="facebook logo" />
          </a>
        )}
        {
          /* istanbul ignore next */
          contactDetails.twitter && (
            <a
              href={contactDetails.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="twitter logo" />
            </a>
          )
        }
        {
          /* istanbul ignore next */
          contactDetails.instagram && (
            <a
              href={contactDetails.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="instagram logo" />
            </a>
          )
        }
        {
          /* istanbul ignore next */
          contactDetails.youtube && (
            <a
              href={contactDetails.youtube}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="youtube logo" />
            </a>
          )
        }
        {
          /* istanbul ignore next */
          contactDetails.linkedin && (
            <a
              href={contactDetails.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="linkedin logo" />
            </a>
          )
        }
        {
          /* istanbul ignore next */
          contactDetails.vimeo && (
            <a
              href={contactDetails.vimeo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={vimeo} alt="vimeo logo" />
            </a>
          )
        }
        {
          /* istanbul ignore next */
          contactDetails.tiktok && (
            <a
              href={contactDetails.tiktok}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tiktok} alt="tiktok logo" />
            </a>
          )
        }
        {
          /* istanbul ignore next */
          contactDetails.generic_social_media_link && (
            <a
              href={contactDetails.generic_social_media_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={generic} alt="generic social logo" />
            </a>
          )
        }
      </div>
    </div>
  );
};

export default ContactDetails;

ContactDetails.propTypes = {
  contactDetails: PropTypes.shape({
    organisation: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    phone_number: PropTypes.string,
    contact_email: PropTypes.string,
    contact_name: PropTypes.string,
    address_1: PropTypes.string,
    address_2: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    postcode: PropTypes.string,
    region: PropTypes.string.isRequired,
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
    facebook: PropTypes.string.isRequired,
    twitter: PropTypes.string.isRequired,
    instagram: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    youtube: PropTypes.string.isRequired,
    vimeo: PropTypes.string.isRequired,
    tiktok: PropTypes.string.isRequired,
    generic_social_media_link: PropTypes.string.isRequired,
  }).isRequired,
};
